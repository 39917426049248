import $ from 'jquery';
import Checkout from './Checkout';
import Repeater from "./Repeater";
import DependedElement from "./DependedElement";

$('#checkout-btn, #trial-checkout-btn').click(function() {
    let redirectUrl = '/index.php?route=checkout/subscription/page&product='+$(this).data('id')+'&free='+$(this).data('free');
    window.location.href = redirectUrl;

    // cart.checkout_add($(this).data('id'))
    //     .done(() => {
    //         window.location.href = '/index.php?route=checkout/subscription/page';
    //     })
    //     .fail(() => {
    //         // todo
    //     })
});

if($('#checkout-page').length) {
    new Checkout();
}

const checkout_popup_content_update = (event, $step_content) => {

    $step_content.find('[data-depend]').each(function() {
        new DependedElement($step_content, $(this).data('depend'), $(this));
    });

    // initialize all select js
    $step_content.find('.select-js').select2({
        minimumResultsForSearch: -1,
        dropdownAutoWidth: true,
        width: 'style',
    });

    // popup tabs
    $step_content.find('.tabs .tab__header').click(function() {
        $('.tab__header, .tab__content').removeClass('active');
        $(this).addClass('active');
        $('.tab__content[data-tab="'+ $(this).data('tab') +'"]').addClass('active');
    });

    // quantity
    $step_content.find('.quantity__button').click(function() {
        const $input = $(this).parent().find('input');
        const oldValue = $input.val();
        let newValue;
        if ($(this).hasClass('quantity__button--up')) {
            newValue = parseFloat(oldValue) + 1;
        } else {
            newValue = oldValue > 1 ? (parseFloat(oldValue) - 1) : oldValue;
            if(newValue < $('.recipients .repeater-field__row').length) {
                alert('In order to be able to reduce the recipients you have first to remove a recipient.');
                return false;
            }
        }
        if (newValue > 1) {
            $('#customer_as_recipient').prop('checked', false);
            $('.customer_as_recipient_div').hide();
        }else{
            $('.customer_as_recipient_div').show();
        }
        $input.val(newValue);
        $input.trigger('cart_quantity_updated');
    });

    $step_content.find('select[name=\'customer_address[country_id]\']').trigger('change');
}
$(document).on('checkout-step:update-content', checkout_popup_content_update);