import $ from "jquery";
import StepsArray from './Utils/StepsArray'
import CheckoutStep from "./CheckoutStep";
import CheckoutPopup from "./CheckoutPopup";

export default class Checkout {
    constructor() {
        this.steps = new StepsArray;
        this.init();
    }

    init() {
        $.ajax({
            method: 'post',
            url: '/index.php?route=checkout/subscription',
            data: {
                product: $('#main-checkout').data('product'),
                free: $('#main-checkout').data('free'),
                one_off: $('#main-checkout').data('oneoff'),
                duplicate_from: $('#main-checkout').data('duplicate')
            },
            success: (data) => {
                // popup
                this.popup = new CheckoutPopup();

                data.steps.map((step, i) => this.addStep(step, i));

                this.steps.curr = data.steps.filter((step) => step.complete).length;

                this.showStepContent();

                // steps events
                $(document).on('checkout-step:submit', (event, step, jqXHR, textStatus) => {

                    const response = jqXHR.responseJSON;

                    step.$content.find('.alert').remove();

                    if('success' === textStatus) {

                        if(response.content) {
                            step.$content.html($(response.content));
                            step.attachEvents();
                            $(document).trigger('checkout-step:update-content', [step.$content]);
                        }

                        if(response.message) {
                            step.$content.prepend($(this.getMessageHtml([response.message])));
                        }

                        if(this.steps.next()) {
                            this.steps.prev().setStatus(true);
                            this.steps.next();
                            this.showStepContent();
                        } else {
                            // We are done
                            this.popup.close();
                        }

                    } else {
                        if(response.error) {
                            step.$content.find('.alert').remove();
                            step.$content.prepend($(this.getMessageHtml(Object.values(response.error))));
                        }
                    }
                });
            },
            error: (xhr, exception) => {
                // todo: error handling
            }
        });
    }

    showStepContent() {
        const _this = this;
        this.steps.current().getContent().then(
            (step) => {
                _this.popup.setContent(step.$content);
            }, (step, jqXHR, textStatus, errorThrown) => {
                _this.popup.setContent($(this.getMessageHtml([textStatus + ': ' + errorThrown])));
            });
    }

    addStep(step, index) {
        step.index = index;
        const checkout_step = new CheckoutStep(step);

        const _this = this;

        checkout_step.editable && checkout_step.$label.click(function() {

            if(!this.hasAttribute('data-complete')) {
                return false;
            }

            _this.steps.seekTo($(this).index());
            for(let i = _this.steps.curr; i < _this.steps.length; i++) {
                _this.steps[i].$label.removeAttr('data-complete');
            }
            _this.showStepContent();
        });

        this.popup.addStep(checkout_step)
        this.steps.push(checkout_step);
    }

    getMessageHtml(errors) {
        let html = `
            <div class="alert alert-danger alert-dismissible" role="alert">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <ul>`;
        errors.forEach((value) => {
            html += `<li>${value}</li>`;
        })
        html += `
                </ul>
            </div>`;

        return html;
    }
}