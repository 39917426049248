import $ from 'jquery';
import RepeaterRow from "./RepeaterRow";

export default class Repeater  {
    constructor($el) {

        this.$el = $el
        this.$template = this.$el.find('.template')
        this.controller = this.$el.data('controller')
        this.add_btn_text = this.$el.data('add-btn')
        this.rmv_btn_text = this.$el.data('remove-btn')
        this.$rows_cnt = this.$el.find('.repeater-field__rows')
        this.max = this.$el.data('max-rows') || -1
        this.is_free = $('#is_free').val()
        this.is_one_off = $('#is_one_off').val()
        this.rows = [];

        this.init();

        this.currentIndex = this.rows.length
    }

    init() {

        // Init rows
        const _this = this;
        this.$rows_cnt.children().each(function() {
            _this.rows.push(new RepeaterRow(_this, this));
        })

        // Add row button
        this.$button = $(`<button type="button" class="cta__default theme--white-border">${this.add_btn_text}</button>`)
        this.$button.click('click', () => {
            this.addRow();
        });

        this.update();
    }

    update() {
        this.rows.forEach((row, index) => {
            row.$el.find('.row__num').text(`#${index+1}`)
            this.singleRepeaterCheck(row);
            this.bundleRepeaterCheck(row);
        });

        if((this.max !== -1 && this.max <= this.rows.length && this.is_free == 1) || this.is_one_off == 1) {
            this.disableRowAdd()
        } else  {
            this.enableRowAdd()
        }

        $(document).trigger('repeater:update', [this]);
    }

    addRow() {
        let event = $.Event("repeater-row:before-add");
        $(document).trigger(event, [this]);
        if(event.isDefaultPrevented()) {
            return;
        }
        const row = new RepeaterRow(this);
        row.$el.appendTo(this.$rows_cnt);
        this.rows.push(row);
        $(document).trigger('repeater-row:add', [row]);
        row.$el.find('.repeater-field__row-content').addClass(`row-content-${this.currentIndex}`);
        this.currentIndex++;

        this.update()
    }

    enableRowAdd() {
        if (!this.button_cloned) {
            this.button_cloned = this.$button.clone(true).appendTo(this.$el);
        }
    }

    disableRowAdd() {
        if(this.button_cloned) {
            this.button_cloned.remove();
            this.button_cloned = null
        }
    }
     bundleRepeaterCheck(row) {
        row.$el.find('[data-product-idtype]').each(function () {
            let product = $(this);
            let productId = product.data('product-idtype').split('-')[1];
            const total_allowed = row.$el.find('[data-product-max]').data('product-max');

            if ($(`.repeater-field__rows input[data-product-idtype="printed-${productId}"]:checked`).length < total_allowed) {
                $(`.repeater-field__rows input[data-product-idtype="printed-${productId}"]:disabled`).each(function () {
                    $(this).removeAttr('disabled');
                    const inputId = $(this).attr('id');
                    $(`label[for="${inputId}"]`).removeClass('disabled');
                });
            } else {
                $(`.repeater-field__rows input[data-product-idtype="printed-${productId}"]:not(:checked)`).each(function () {
                    $(this).attr('disabled', true);
                    const inputId = $(this).attr('id');
                    $(`label[for="${inputId}"]`).addClass('disabled');
                });
            }
        });
    }

    singleRepeaterCheck(row){
        if (row.repeater.max !== -1){
            if ($('.repeater-field__rows input[data-product-type="Έντυπη μορφή"]:checked').length < row.repeater.max){
                $('.repeater-field__rows input[data-product-type="Έντυπη μορφή"]:disabled').each(function() {
                    $(this).removeAttr('disabled');
                    const inputId = $(this).attr('id');
                    $(`label[for="${inputId}"]`).each(function() {
                        $(this).removeClass('disabled');
                    });
                });
            }else{
                $('.repeater-field__rows input[data-product-type="Έντυπη μορφή"]:not(:checked)').each(function() {
                    $(this).attr('disabled', true);
                    const inputId = $(this).attr('id');
                    $(`label[for="${inputId}"]`).each(function() {
                        $(this).addClass('disabled');
                    });
                });
            }
        }
    }
}